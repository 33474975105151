<template>
  <modal ref="modal" class-name="min-h-500px max-w-full w-full sm:w-700px">
    <h5 class="mb-16">Create a template</h5>

    <form class="flex flex-col gap-4" @submit.prevent="submit">
      <form-group
        v-model="form.data.subject.value"
        :form="form"
        name="subject"
        label="Subject"
        placeholder="Enter Subject..."
      />
      <form-group
        v-model="form.data.body.value"
        :form="form"
        name="body"
        label="Body"
        placeholder="Enter Body..."
        type="textarea"
      />
      <button
        type="submit"
        class="btn btn-md btn-blue"
        :disabled="form.loading"
      >
        <sm-loader-white v-if="form.loading" />
        <span v-else>Create Template</span>
      </button>
    </form>
  </modal>
</template>

<script>
export default {
  name: 'CreateCommunicationTemplate',
  data() {
    return {
      form: this.$options.basicForm(['subject', 'body']),
    };
  },
  computed: {},
  mounted() {},
  methods: {
    close() {
      this.$refs.modal.close();
    },
    open() {
      this.$refs.modal.open();
    },
    async submit() {
      if (!this.validateForm(this.form)) return;
      this.form.loading = true;
      await this.sendRequest('admin.communications.templates.create', {
        data: this.getFormData(),
        success: () => {
          this.$emit('update');
          this.close();
          this.$success({
            title: 'Operation Successful',
            body: 'Template was created.',
            button: 'Okay',
          });
          this.form = this.resetForm(this.form);
        },
        error: (error) => {
          this.$error({ title: `${error?.response?.data?.message}` });
        },
      });
      this.form.loading = false;
    },
  },
};
</script>
